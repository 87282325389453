<template>
    <div class="dark-green-l-grad py-3">
        <div class="container">
            <div class="fl-resp">
                <div class="mb-2 mb-lg-0">
                    <div class="d-inline social-media">
                        <a href="" class="social-icon border-r-1"><i class="fa fa-facebook"></i></a>
                        <a href="" class="social-icon border-r-1"><i class="fa fa-instagram"></i></a>
                        <a href="" class="social-icon border-r-1"><i class="fa fa-envelope"></i></a>
                        <a href="" class="social-icon border-r-1"><i class="fa fa-linkedin"></i></a>
                    </div>
                </div>
                <div class="fs--1">
                    <p class="text-white m-0 op-7 text-center text-lg-right">
<!--                        <span-->
<!--                            class="text-lg-right w-100 text-white">-->
<!--                            <span v-if="termsFile" class="link" @click="viewFile('terms')">Terms and Condition</span>-->
<!--                            <span v-else>Terms and Condition</span>-->
<!--                            |-->
<!--                            <span v-if="privacyFile" class="link" @click="viewFile('privacy')">Privacy Policy</span>-->
<!--                            <span v-else>Privacy Policy</span>-->
<!--                        </span>-->
                        <br>
                        <span class="">Copyright &copy; 2020-21 Kerala Live Stock Development Board</span>
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'secure-axios';
import urls from '../../data/urls';

export default {
    name: 'SubFooter',
    data () {
        return {
            details: '',
            DataLoading: false,
            privacyId: '',
            privacyFile: '',
            termsId: '',
            termsFile: ''
        };
    },
    mounted () {
        this.loadDetails();
    },
    methods: {
        async loadDetails () {
            const that = this;
            that.DataLoading = true;
            const response = await axios.get(urls.admin.policy.list);
            const json = response.data;
            if (json) {
                that.details = json.data;
                if (that.details.length !== 0) {
                    that.setId(that.details);
                }
            } else {
                that.$notify('Details not found, Please try again later.', 'No Data Found', {
                    type: 'info'
                });
            }
            that.DataLoading = false;
        },
        setId (item) {
            for (let i = 0; i <= item.length - 1; i++) {
                if (item[i].policy === 'privacy') {
                    this.privacyId = item[i].id;
                    this.privacyFile = item[i].file;
                }
                if (item[i].policy === 'terms') {
                    this.termsId = item[i].id;
                    this.termsFile = item[i].file;
                }
            }
        },
        viewFile (item) {
            if (item === 'privacy') {
                window.open('https://kldb.xeoscript.com/upload/' + this.privacyFile);
            }
            if (item === 'terms') {
                window.open('https://kldb.xeoscript.com/upload/' + this.termsFile);
            }
        }
    }
};
</script>
<style>
.link:hover {
    color: #00ABF3E6;
    cursor: pointer;
}
</style>
<style scoped lang="scss">
// .social-media {

//     @media (min-width: 992px) {
//         position: absolute;
//         right: 0;
//     }

// }
.fl-resp {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    @media (min-width: 992px) {
        flex-direction: row;
        justify-content: space-between;
    }
}

.social-icon {
    padding: 1rem;
    transition: all 300ms ease-in-out;
    border: 2px solid;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 40px;
    color: var(--color-white);
    background: #c9c9c926;
    margin-left: 10px;
    text-decoration: none;
    cursor: pointer;

    &:hover {
        opacity: 0.8;
        cursor: pointer;
        // background-color: var(--primary-900);
        // color: black;
    }
}

.ml-lg-auto {
    @media (min-width: 992px) {
        margin-left: auto !important;
    }
}
</style>
