<template>
    <div class="bg-2" id="top">
        <notifications/>
        <div class="nav-container" v-bind:class="newAddClass()">
            <lego-nav-bar drop-down-animation="bounce" shadow="0" class="container" drop-down-animation-mobile="fade"
                          :items="menu">
                <template #logo>
                    <router-link to="/" class="d-flex">
                        <img src="../assets/img/logo/logo.png" alt="">
                    </router-link>
                </template>
            </lego-nav-bar>
        </div>

        <div class="">
            <transition name="fade" mode="out-in">
                <router-view @detailsClicked="newAddClass">

                </router-view>
            </transition>
        </div>

        <!--        <section>-->
        <!--            <main-footer></main-footer>-->
        <!--        </section>-->
        <section>
            <!--            <main-footer></main-footer>-->
            <sub-footer></sub-footer>
        </section>
    </div>

</template>

<script>
import LegoNavBar from 'lego-framework/src/components/NavBar';
import getMenu from '../data/menu';
// import MainFooter from '../views/components/MainFooter';
import SubFooter from '../views/components/SubFooter';
// import MainFooter from '@/views/components/MainFooter';

export default {
    components: {
        // MainFooter,
        // MainFooter,
        SubFooter,
        LegoNavBar
    },
    data () {
        return {
            menu: getMenu(),
            value: ''
        };
    },
    methods: {
        addClass () {
            const path = this.$route.path !== '/bullinfo/';
            if (path) {
                return [''];
            } else {
                return ['light'];
            }
        },
        newAddClass (item) {
            if (item === false) {
                return [''];
            } else {
                return ['light'];
            }
        }
    },
    metaInfo: {
        titleTemplate: '%s - Project Name',
        htmlAttrs: {
            lang: 'en',
            amp: true
        }
    },
    name: 'MainLayout'
};
</script>

<style>
.fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
    opacity: 0;
}
</style>
